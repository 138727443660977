// Generated by ReScript, PLEASE EDIT WITH CARE

import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Form from "../../../styleguide/forms/Form/Form.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as React from "react";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as Textarea from "../../../styleguide/forms/Textarea/Textarea.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as Js_string from "rescript/lib/es6/js_string.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ErrorMessage from "../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as ReactTelInput from "../../../bindings/ReactTelInput.res.js";
import * as Globals from "Common/globals";
import * as TelephoneField from "../../../styleguide/forms/TelephoneField/TelephoneField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserEditProfileData from "./UserEditProfileData.res.js";
import * as UserEditProfileGeneralTabScss from "./UserEditProfileGeneralTab.scss";
import * as UserEditProfileAssetsTabUploader from "./UserEditProfileAssetsTabUploader.res.js";

var css = UserEditProfileGeneralTabScss;

function UserEditProfileGeneralTab(props) {
  var update = props.update;
  var results = props.results;
  var input = props.input;
  var pickFilesToUpload = React.useRef(undefined);
  var validateImageBeforeUpload = React.useCallback((function (file) {
          $$Promise.wait(Api.uploadFilesToCloudinary({
                    NAME: "Image",
                    VAL: file
                  }), (function (x) {
                  if (x.TAG !== "Ok") {
                    return SentryLogger.error1({
                                rootModule: "UserEditProfileGeneralTab",
                                subModulePath: /* [] */0,
                                value: "make",
                                fullPath: "UserEditProfileGeneralTab.make"
                              }, "UserEditProfileGeneralTab::UploadImageToCloudinary::Error", [
                                "Error",
                                x._0
                              ]);
                  }
                  var res = UserEditProfileData.ImageResponse.fromJson(x._0);
                  update({
                        firstName: input.firstName,
                        lastName: input.lastName,
                        email: input.email,
                        phone: input.phone,
                        description: input.description,
                        jobTitle: input.jobTitle,
                        profilePic: res.public_id,
                        dialCountryCode: input.dialCountryCode,
                        country: input.country,
                        password: input.password,
                        providerNameRequested: input.providerNameRequested,
                        agency: input.agency
                      });
                }));
        }), [update]);
  var profileImage = UserEditProfileData.Input.getProfileImage(input.profilePic, Globals.CloudinaryCloudName);
  var tmp;
  if (results !== undefined) {
    var message = results.firstName;
    tmp = message.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message._0
          });
  } else {
    tmp = null;
  }
  var tmp$1;
  if (results !== undefined) {
    var message$1 = results.lastName;
    tmp$1 = message$1.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$1._0
          });
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  if (results !== undefined) {
    var message$2 = results.phone;
    tmp$2 = message$2.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$2._0
          });
  } else {
    tmp$2 = null;
  }
  var tmp$3;
  if (results !== undefined) {
    var message$3 = results.email;
    tmp$3 = message$3.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$3._0
          });
  } else {
    tmp$3 = null;
  }
  var tmp$4;
  var exit = 0;
  switch (props.userRole) {
    case "User" :
    case "Agent" :
        exit = 1;
        break;
    default:
      tmp$4 = null;
  }
  if (exit === 1) {
    tmp$4 = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx(Label.make, {
                        forId: "edit-profile-form--agency-name",
                        children: "Agency"
                      }),
                  JsxRuntime.jsx(TextField.make, {
                        id: "edit-profile-form--agency-name",
                        value: input.agency,
                        placeholder: "Agency Name",
                        onChange: (function ($$event) {
                            update({
                                  firstName: input.firstName,
                                  lastName: input.lastName,
                                  email: input.email,
                                  phone: input.phone,
                                  description: input.description,
                                  jobTitle: input.jobTitle,
                                  profilePic: input.profilePic,
                                  dialCountryCode: input.dialCountryCode,
                                  country: input.country,
                                  password: input.password,
                                  providerNameRequested: input.providerNameRequested,
                                  agency: $$event.target.value
                                });
                          })
                      })
                ],
                className: css.fieldColumn
              }),
          className: css.fieldRow
        });
  }
  var x = input.description;
  var tmp$5;
  if (results !== undefined) {
    var message$4 = results.password;
    tmp$5 = message$4.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$4._0
          });
  } else {
    tmp$5 = null;
  }
  return JsxRuntime.jsx(Container.make, {
              className: css.paddingHorizontalZero,
              children: JsxRuntime.jsxs(Form.make, {
                    className: css.editProfileForm,
                    onSubmit: props.submit,
                    children: [
                      JsxRuntime.jsx(H2.make, {
                            className: css.titleMain,
                            children: "General Information"
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(UserEditProfileAssetsTabUploader.make, {
                                    uploadImage: validateImageBeforeUpload,
                                    setUploadHandler: (function (fn) {
                                        pickFilesToUpload.current = fn;
                                      }),
                                    profileImagePath: profileImage,
                                    hasProfilePic: input.profilePic
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-profile-form--last-name",
                                                    children: "First Name"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "edit-profile-form--first-name",
                                                    value: input.firstName,
                                                    placeholder: "First Name",
                                                    status: Belt_Option.map(results, (function (x) {
                                                            if (x.firstName.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    onChange: (function ($$event) {
                                                        update({
                                                              firstName: $$event.target.value,
                                                              lastName: input.lastName,
                                                              email: input.email,
                                                              phone: input.phone,
                                                              description: input.description,
                                                              jobTitle: input.jobTitle,
                                                              profilePic: input.profilePic,
                                                              dialCountryCode: input.dialCountryCode,
                                                              country: input.country,
                                                              password: input.password,
                                                              providerNameRequested: input.providerNameRequested,
                                                              agency: input.agency
                                                            });
                                                      })
                                                  }),
                                              tmp
                                            ],
                                            className: css.fieldColumn
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-profile-form--last-name",
                                                    children: "Last Name"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "edit-profile-form--last-name",
                                                    value: input.lastName,
                                                    placeholder: "Last Name",
                                                    status: Belt_Option.map(results, (function (x) {
                                                            if (x.lastName.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    onChange: (function ($$event) {
                                                        update({
                                                              firstName: input.firstName,
                                                              lastName: $$event.target.value,
                                                              email: input.email,
                                                              phone: input.phone,
                                                              description: input.description,
                                                              jobTitle: input.jobTitle,
                                                              profilePic: input.profilePic,
                                                              dialCountryCode: input.dialCountryCode,
                                                              country: input.country,
                                                              password: input.password,
                                                              providerNameRequested: input.providerNameRequested,
                                                              agency: input.agency
                                                            });
                                                      })
                                                  }),
                                              tmp$1
                                            ],
                                            className: css.fieldColumn
                                          })
                                    ],
                                    className: css.twoInRow
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-profile-form--phone",
                                                    children: "Phone"
                                                  }),
                                              JsxRuntime.jsx(TelephoneField.make, {
                                                    id: "edit-profile-form--phone",
                                                    value: input.country + input.phone,
                                                    defaultCountry: "us",
                                                    status: Belt_Option.map(results, (function (x) {
                                                            if (x.phone.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    onChange: (function (value, selectedCountry) {
                                                        var country = ReactTelInput.ReactTelInputData.countryFromJs(selectedCountry);
                                                        update({
                                                              firstName: input.firstName,
                                                              lastName: input.lastName,
                                                              email: input.email,
                                                              phone: "+" + (country.dialCode + Js_string.replace("+" + country.dialCode, "", value).trim()),
                                                              description: input.description,
                                                              jobTitle: input.jobTitle,
                                                              profilePic: input.profilePic,
                                                              dialCountryCode: "+" + country.dialCode,
                                                              country: country.iso2.toUpperCase(),
                                                              password: input.password,
                                                              providerNameRequested: input.providerNameRequested,
                                                              agency: input.agency
                                                            });
                                                      }),
                                                    preferredCountries: ["us"]
                                                  }),
                                              tmp$2
                                            ],
                                            className: css.fieldColumn
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-profile-form--email",
                                                    children: "Email"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "edit-profile-form--email",
                                                    value: input.email,
                                                    placeholder: "Email",
                                                    status: Belt_Option.map(results, (function (x) {
                                                            if (x.email.TAG === "Ok") {
                                                              return "Valid";
                                                            } else {
                                                              return "Error";
                                                            }
                                                          })),
                                                    onChange: (function ($$event) {
                                                        update({
                                                              firstName: input.firstName,
                                                              lastName: input.lastName,
                                                              email: $$event.target.value,
                                                              phone: input.phone,
                                                              description: input.description,
                                                              jobTitle: input.jobTitle,
                                                              profilePic: input.profilePic,
                                                              dialCountryCode: input.dialCountryCode,
                                                              country: input.country,
                                                              password: input.password,
                                                              providerNameRequested: input.providerNameRequested,
                                                              agency: input.agency
                                                            });
                                                      })
                                                  }),
                                              tmp$3
                                            ],
                                            className: css.fieldColumn
                                          })
                                    ],
                                    className: css.twoInRow
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-profile-form--company-name",
                                                    children: "Company"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "edit-profile-form--company-name",
                                                    value: input.providerNameRequested,
                                                    placeholder: "Company Name",
                                                    onChange: (function ($$event) {
                                                        update({
                                                              firstName: input.firstName,
                                                              lastName: input.lastName,
                                                              email: input.email,
                                                              phone: input.phone,
                                                              description: input.description,
                                                              jobTitle: input.jobTitle,
                                                              profilePic: input.profilePic,
                                                              dialCountryCode: input.dialCountryCode,
                                                              country: input.country,
                                                              password: input.password,
                                                              providerNameRequested: $$event.target.value,
                                                              agency: input.agency
                                                            });
                                                      })
                                                  })
                                            ],
                                            className: css.fieldColumn
                                          }),
                                      JsxRuntime.jsxs("div", {
                                            children: [
                                              JsxRuntime.jsx(Label.make, {
                                                    forId: "edit-profile-form--job-title",
                                                    children: "Job Title"
                                                  }),
                                              JsxRuntime.jsx(TextField.make, {
                                                    id: "edit-profile-form--job-title",
                                                    value: input.jobTitle,
                                                    placeholder: "Job Title",
                                                    onChange: (function ($$event) {
                                                        update({
                                                              firstName: input.firstName,
                                                              lastName: input.lastName,
                                                              email: input.email,
                                                              phone: input.phone,
                                                              description: input.description,
                                                              jobTitle: $$event.target.value,
                                                              profilePic: input.profilePic,
                                                              dialCountryCode: input.dialCountryCode,
                                                              country: input.country,
                                                              password: input.password,
                                                              providerNameRequested: input.providerNameRequested,
                                                              agency: input.agency
                                                            });
                                                      })
                                                  })
                                            ],
                                            className: css.fieldColumn
                                          })
                                    ],
                                    className: css.twoInRow
                                  }),
                              tmp$4,
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "edit-profile-form--job-title",
                                                  children: "BIO"
                                                }),
                                            JsxRuntime.jsx(Textarea.make, {
                                                  id: "edit-profile-form--description",
                                                  value: x !== undefined ? x : "",
                                                  rows: 5,
                                                  placeholder: "Description",
                                                  onChange: (function ($$event) {
                                                      update({
                                                            firstName: input.firstName,
                                                            lastName: input.lastName,
                                                            email: input.email,
                                                            phone: input.phone,
                                                            description: $$event.target.value,
                                                            jobTitle: input.jobTitle,
                                                            profilePic: input.profilePic,
                                                            dialCountryCode: input.dialCountryCode,
                                                            country: input.country,
                                                            password: input.password,
                                                            providerNameRequested: input.providerNameRequested,
                                                            agency: input.agency
                                                          });
                                                    })
                                                })
                                          ],
                                          className: css.fieldColumn
                                        }),
                                    className: css.fieldRow
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Label.make, {
                                                  forId: "edit-profile-form--password",
                                                  children: "Current Password"
                                                }),
                                            JsxRuntime.jsx(TextField.make, {
                                                  id: "edit-profile-form--password",
                                                  type_: "Password",
                                                  value: input.password,
                                                  placeholder: "Current Password",
                                                  status: Belt_Option.map(results, (function (x) {
                                                          if (x.password.TAG === "Ok") {
                                                            return "Valid";
                                                          } else {
                                                            return "Error";
                                                          }
                                                        })),
                                                  onChange: (function ($$event) {
                                                      update({
                                                            firstName: input.firstName,
                                                            lastName: input.lastName,
                                                            email: input.email,
                                                            phone: input.phone,
                                                            description: input.description,
                                                            jobTitle: input.jobTitle,
                                                            profilePic: input.profilePic,
                                                            dialCountryCode: input.dialCountryCode,
                                                            country: input.country,
                                                            password: $$event.target.value,
                                                            providerNameRequested: input.providerNameRequested,
                                                            agency: input.agency
                                                          });
                                                    })
                                                }),
                                            tmp$5,
                                            JsxRuntime.jsx("div", {
                                                  children: "Enter your current password to confirm your changes",
                                                  className: css.fieldHint
                                                })
                                          ],
                                          className: css.fieldColumn
                                        }),
                                    className: css.fieldRowPassword
                                  })
                            ]
                          }),
                      props.children
                    ]
                  })
            });
}

var ReactTelInputData;

var make = UserEditProfileGeneralTab;

export {
  css ,
  ReactTelInputData ,
  make ,
}
/* css Not a pure module */
