// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as Uploader from "../../../styleguide/forms/Uploader/Uploader.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconUserAvatar from "../../../styleguide/icons/IconUserAvatar.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserEditProfileScss from "./UserEditProfile.scss";

var css = UserEditProfileScss;

function UserEditProfileAssetsTabUploader(props) {
  var uploadImage = props.uploadImage;
  var tmp = props.hasProfilePic === "" ? JsxRuntime.jsx(IconUserAvatar.make, {
          size: "XXXXXL",
          className: css.defaultProfilePic
        }) : JsxRuntime.jsx("img", {
          alt: "Profile Image",
          src: props.profileImagePath
        });
  return JsxRuntime.jsxs(Uploader.make, {
              allowed: [
                "Jpg",
                "Png"
              ],
              multiple: false,
              className: css.uploader,
              upload: (function (files) {
                  Belt_Array.map(files, (function (file) {
                          var match = Uploader.FileType.fromMime(file.type);
                          switch (match) {
                            case "Jpg" :
                            case "Png" :
                            case "Avi" :
                            case "Mp4" :
                            case "Mov" :
                            case "Wmv" :
                                return uploadImage(file);
                            default:
                              return ;
                          }
                        }));
                }),
              setUploadHandler: props.setUploadHandler,
              children: [
                JsxRuntime.jsx("div", {
                      children: tmp,
                      className: css.uploadedImage
                    }),
                JsxRuntime.jsxs("div", {
                      children: [
                        "Drag and Drop Image",
                        JsxRuntime.jsx(Button.make, {
                              size: "SM",
                              color: "Teal",
                              children: "Or Select File"
                            })
                      ],
                      className: css.uploaderInner
                    })
              ]
            });
}

var make = UserEditProfileAssetsTabUploader;

export {
  css ,
  make ,
}
/* css Not a pure module */
