// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H2 from "../../../styleguide/components/Heading/H2.res.js";
import * as Form from "../../../styleguide/forms/Form/Form.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as ErrorMessage from "../../../styleguide/forms/ErrorMessage/ErrorMessage.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserEditProfileChangePasswordData from "./UserEditProfileChangePasswordData.res.js";
import * as UserEditProfileChangePasswordTabScss from "./UserEditProfileChangePasswordTab.scss";

var css = UserEditProfileChangePasswordTabScss;

function UserEditProfileChangePasswordTab(props) {
  var update = props.update;
  var results = props.results;
  var input = props.input;
  var tmp;
  if (results !== undefined) {
    var message = results.currentPassword;
    tmp = message.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message._0
          });
  } else {
    tmp = null;
  }
  var tmp$1;
  if (results !== undefined) {
    var message$1 = results.password;
    tmp$1 = message$1.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$1._0
          });
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  if (results !== undefined) {
    var message$2 = results.confirmPassword;
    tmp$2 = message$2.TAG === "Ok" ? null : JsxRuntime.jsx(ErrorMessage.make, {
            className: css.error,
            children: message$2._0
          });
  } else {
    tmp$2 = null;
  }
  return JsxRuntime.jsx(Container.make, {
              className: css.paddingHorizontalZero,
              children: JsxRuntime.jsxs(Form.make, {
                    className: css.editProfileFormChangePassword,
                    onSubmit: props.submit,
                    children: [
                      JsxRuntime.jsx(H2.make, {
                            className: css.titleMain,
                            children: "Change Password"
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Label.make, {
                                          forId: "edit-profile-form--current-password",
                                          children: "Current Password"
                                        }),
                                    JsxRuntime.jsx(TextField.make, {
                                          id: "edit-profile-form--current-password",
                                          type_: "Password",
                                          value: input.currentPassword,
                                          placeholder: "Current Password",
                                          status: Belt_Option.map(results, (function (x) {
                                                  if (x.currentPassword.TAG === "Ok") {
                                                    return "Valid";
                                                  } else {
                                                    return "Error";
                                                  }
                                                })),
                                          onChange: (function ($$event) {
                                              update({
                                                    currentPassword: $$event.target.value,
                                                    password: input.password,
                                                    confirmPassword: input.confirmPassword
                                                  });
                                            })
                                        }),
                                    tmp
                                  ],
                                  className: css.fieldColumn
                                }),
                            className: css.fieldRow
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsxs(Label.make, {
                                          forId: "edit-profile-form--password",
                                          children: [
                                            "New Password",
                                            JsxRuntime.jsx("span", {
                                                  children: " (8-20 characters)",
                                                  className: css.labelInfo
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsx(TextField.make, {
                                          id: "edit-profile-form--password",
                                          type_: "Password",
                                          value: input.password,
                                          placeholder: "New Password",
                                          status: Belt_Option.map(results, (function (x) {
                                                  if (x.password.TAG === "Ok") {
                                                    return "Valid";
                                                  } else {
                                                    return "Error";
                                                  }
                                                })),
                                          onChange: (function ($$event) {
                                              update({
                                                    currentPassword: input.currentPassword,
                                                    password: $$event.target.value,
                                                    confirmPassword: input.confirmPassword
                                                  });
                                            })
                                        }),
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsxs("span", {
                                                  children: [
                                                    "Password strength: ",
                                                    UserEditProfileChangePasswordData.Input.passwordStrength(input.password)
                                                  ],
                                                  className: css.title
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("div", {
                                                        className: Cx.cx([
                                                              css.progressBar,
                                                              input.password.length === 6 || input.password.length === 7 ? css.medium : "",
                                                              input.password.length >= 8 ? css.strong : ""
                                                            ])
                                                      }),
                                                  className: css.progress
                                                })
                                          ],
                                          className: css.passwordStrenght
                                        }),
                                    tmp$1
                                  ],
                                  className: css.fieldColumn
                                }),
                            className: css.fieldRow
                          }),
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Label.make, {
                                          forId: "edit-profile-form--confirm-password",
                                          children: "Confirm Password"
                                        }),
                                    JsxRuntime.jsx(TextField.make, {
                                          id: "edit-profile-form--confirm-password",
                                          type_: "Password",
                                          value: input.confirmPassword,
                                          placeholder: "Confirm Password",
                                          status: Belt_Option.map(results, (function (x) {
                                                  if (x.confirmPassword.TAG === "Ok") {
                                                    return "Valid";
                                                  } else {
                                                    return "Error";
                                                  }
                                                })),
                                          onChange: (function ($$event) {
                                              update({
                                                    currentPassword: input.currentPassword,
                                                    password: input.password,
                                                    confirmPassword: $$event.target.value
                                                  });
                                            })
                                        }),
                                    tmp$2
                                  ],
                                  className: css.fieldColumn
                                }),
                            className: css.fieldRow
                          }),
                      props.children
                    ]
                  })
            });
}

var Input;

var make = UserEditProfileChangePasswordTab;

export {
  css ,
  Input ,
  make ,
}
/* css Not a pure module */
