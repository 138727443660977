// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Container from "../../../styleguide/components/Container/Container.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserEditProfileScss from "./UserEditProfile.scss";

var css = UserEditProfileScss;

function UserProfileEditorActionBar(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Container.make, {
                    className: css.actionBarInner,
                    children: props.children
                  }),
              className: css.actionBar
            });
}

function UserProfileEditorActionBar$ContextControls(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: css.actionBarLeftSideControlsContainer
            });
}

var ContextControls = {
  make: UserProfileEditorActionBar$ContextControls
};

function UserProfileEditorActionBar$Note(props) {
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: css.actionBarNote
            });
}

var Note = {
  make: UserProfileEditorActionBar$Note
};

function UserProfileEditorActionBar$Message(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  return JsxRuntime.jsx("div", {
              children: props.children,
              className: Cx.cx([
                    css.actionBarMessage,
                    className
                  ])
            });
}

var Message = {
  make: UserProfileEditorActionBar$Message
};

var make = UserProfileEditorActionBar;

export {
  css ,
  make ,
  ContextControls ,
  Note ,
  Message ,
}
/* css Not a pure module */
